<template>
  <div class="index" v-if="$route.name == '公益活动'">
    <div class="indexTop">
      <div style="height: 100px"></div>
      <div class="indexTopOne">韬儒关爱老人活动</div>
      <div class="indexToptwo">进行中</div>
      <div class="indexTopThree" @click="routeTo('/loveOrder')">立即查看</div>
    </div>
    <!-- // -->
    <div class="box">
      <div class="nav">
        <div class="navO">最新动态</div>
        <div class="navT">关于韬儒的最新活动</div>
      </div>
      <!-- 关爱老人 -->
      <div class="care" id="care">
        <p class="goldStoreTitle">
          关爱老人 <icon class="iconfont iconxiangyou1"></icon>
        </p>
         <div class="careBox">
          <div class="careBoxLeft">
            <!-- <img src="@/assets/images/item_img.png" alt="" /> -->
            <video :src="activeOne[0] && activeOne[0].activitiesThumb" class="img"></video>
            <img
              @click="isShowVideoC(0,activeOne[0].activitiesThumb)"
              class="videoPlay"
              src="@/assets/images/play.png"
              alt=""
            />
            <p>{{activeOne[0] && activeOne[0].activitiesTitle}}</p>
          </div>
          <div class="careBoxRight">
            <div
              class="careBoxRightBox"
              v-for="(item, index) in activeTwo"
              :key="index"
              @click="actClick(item.id)"
            >
              <img :src="item.activitiesThumb" alt="" />
              <div>
                <p>{{item.activitiesTitle}}</p>
                 <p v-html="item.activitiesContent"></p>
                <p><span></span><span>{{ item.updateTime | formatDate('yyyy-MM-dd')}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gyActive">
        <div class="gyActiveLeft">
          <div>
            <div>韬儒公益</div>
            <div>
              健康老龄化是80年代后期，由于世界人口老龄化的发展而产生的一个新概念。老龄健康不仅停留在关注老年人在身体各方面生理机能的健康，还应该更多地探索老人在基本认知、记忆、情绪、社会交往等多方面的心理健康。暮柏网希望通过更多关爱老年人心理健康提高老年整体生活品质。
            </div>
          </div>
        </div>
        <div class="gyActiveRight">
          <div class="gyActiveRightTop">
            <video :src="activeOne[0] && activeOne[0].activitiesThumb"></video>
            <img
              @click="isShowVideoC(0,activeOne[0].activitiesThumb)"
              class="videoPlay"
              src="@/assets/images/play.png"
              alt=""
            />
          </div>
          <div class="gyActiveRightMain">
            <div class="gyActiveRightTopMain">
              <video :src="activeOne[0] &&  activeOne[0].activitiesThumb"></video>
              <img
                @click="isShowVideoC(0,activeOne[0].activitiesThumb)"
                class="videoPlay"
                src="@/assets/images/play.png"
                alt=""
              />
            </div>
            <div class="gyActiveRightTopMain1">
              <video :src="activeOne[0] &&  activeOne[0].activitiesThumb"></video>
              <img
                @click="isShowVideoC(0,activeOne[0].activitiesThumb)"
                class="videoPlay"
                src="@/assets/images/play.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /// -->
      <div class="box"  v-if="activeThree.length>0">
        <div class="nav">
          <div class="navO">公益项目</div>
          <div class="navT">关于韬儒的最新活动</div>
        </div>
      </div>

      <div class="recommendGoods" v-if="activeThree.length>0">
        <div class="click_left" @click="clickRight">
          <i class="iconfont iconarrow-left"></i>
        </div>
        <div class="click_right" @click="clickLeft">
          <i class="iconfont iconarrow-right"></i>
        </div>
        <div class="recommendGoodsList">
          <ul :style="`transform: translateX(-${284 * clickCount}px);`">
            <li v-for="(item,index) in activeThree" :key="index">
              <img :src="item.activitiesThumb" />
              <div class="desc">
                <div>{{item.activitiesTitle}}</div>
                <div></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- // -->
      <!-- 猜你喜欢 -->
      <div class="hotspot" id="love" style="margin-bottom: 40px">
        <p class="goldStoreTitle">课程</p>
        <div class="hotspotBox">
          <div class="hotspotBoxSm" v-for="(item, index) in list" :key="index" @click="goodsDetail(item)">
            <img :src="item.goodsPic" alt="" />
            <p class="hotspotBoxSmp">{{ item.goodsName }}</p>
            <div class="hotspotBoxPri">
              <div>
                <span>¥{{ item.goodsDiscountPrice }}</span>
                <span>¥{{item.goodsCommodityPrice}}</span>
              </div>
              <div>销量:{{item.goodsSalesNum || 0}}</div>
            </div>
          </div>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="homePageYoulikeList"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), homePageYoulikeList();
              }
            "
          />
        </Row>
      </div>
    </div>

    <!-- 视频弹框 -->
    <div class="videoModal" v-if="isShowVideo">
      <div class="videoContent">
        <video controls autoplay :src="videoURL" ref="videoPlay"></video>
        <Icon
          class="close"
          @click="isShowVideo = false"
          type="ios-close-circle"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  name: "index",
  components: {},
  data() {
    return {
      clickCount: 0,
      isShowVideo: false,
      txt: "",
      activeOne: [],
      activeTwo: [],
      activeThree: [],
      list: [],
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      total: 0,
    };
  },
  mounted() {
    this.actO();
    this.actT();
    this.actH();
    this.courseList();
  },
  methods: {
    actClick(id){
      this.$router.push({ path: '/activeText', query: {'iId':id} });
    },
    isShowVideoC(tet,url) {
      if (tet == 0) {
        this.isShowVideo = true;
        console.log(url)
        //this.videoURL = "https://taorupingtai.oss-cn-beijing.aliyuncs.com/data/upload/2020122850315.mp4";
        this.videoURL = url
        // 如果动态切换src地址，也要加上下面的事件（前提是src地址已切换或已重新赋值，再走这个事件）
        this.$nextTick(() => {
          this.$refs.videoPlay.load();
        });
      }
    },
    clickLeft() {
      if(this.activeThree.length<4){return}
      this.clickCount++;
      this.clickCount > 7 && (this.clickCount = 7);
    },
    clickRight() {
      this.clickCount--;
      this.clickCount < 0 && (this.clickCount = 0);
    },
    //课程
    courseList() {
      let searchForm = this.searchForm;
      api.courseList(searchForm, (res) => {
        this.list = res.resultList;
        this.total = res.resultCount || 0;
      });
    },
    //关爱老人
    actO() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.activitiesType = 2;
      api.publicaAtiivitiesActiivitiesPage(searchForm, (res) => {
        this.activeOne = res.resultList;
        // .slice(0, 1)
      });
    },
    actT() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.activitiesType = 1;
      api.publicaAtiivitiesActiivitiesPage(searchForm, (res) => {
        this.activeTwo = res.resultList.slice(0, 3);
      });
    },
    actH() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.activitiesType = 3;
      api.publicaAtiivitiesActiivitiesPage(searchForm, (res) => {
        this.activeThree = res.resultList.slice(0, 3);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  margin-bottom: 50px;
  .indexTop {
    width: 100%;
    height: 400px;
    background: url("../../../assets/images/parkImg.png") no-repeat center;
    background-size: cover;
    .indexTopOne {
      width: 100%;
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
      line-height: 67px;
      text-align: center;
      letter-spacing: 3px;
    }
    .indexToptwo {
      width: 100%;
      height: 33px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      text-align: center;
      letter-spacing: 1px;
      margin-top: 16px;
    }
    .indexTopThree {
      width: 202px;
      height: 43px;
      border: 1px solid #ffffff;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 43px;
      margin: 40px auto 0;
      cursor: pointer;
    }
  }
  .box {
    width: 1120px;
    margin: auto;
    .nav {
      width: 100%;
      .navO {
        width: 100%;
        height: 50px;
        font-size: 36px;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 50px;
        letter-spacing: 2px;
        margin-top: 28px;
        text-align: center;
      }
      .navT {
        width: 100%;
        height: 33px;
        font-size: 24px;
        font-weight: 400;
        color: #c0c0c0;
        line-height: 33px;
        letter-spacing: 1px;
        margin-top: 8px;
        text-align: center;
      }
    }
  }
  .care {
    width: 1120px;
    margin: 20px auto 0;
    padding: 24px 24px 0;
    background: #ffffff;
    .goldStoreTitle {
      height: 33px;
      font-size: 24px;
      font-weight: 400;
      color: #1a1a1a;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .iconxiangyou1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 33px;
        color: rgba(41, 150, 84, 1);
        margin: 2px 0 0 8px;
      }
    }
    .careBox {
      display: flex;
      justify-content: space-between;
      .careBoxLeft {
        cursor: pointer;
        width: 524px;
        margin-top: 12px;
        padding-bottom: 24px;
        position: relative;
        .img {
          width: 524px;
          height: 360px;
          border-radius: 2px;
        }
        video:focus {
          outline: none;
        }
        .videoPlay {
          position: absolute;
          width: 80px;
          height: 80px;
          left: 210px;
          top: 140px;
          cursor: pointer;
        }
        p {
          margin-top: 12px;
        }
      }
      .careBoxRight {
        cursor: pointer;
        width: 524px;
        margin-top: 12px;
        border-left: 1px solid #e9e9e9;
        padding-left: 12px;
        padding-bottom: 24px;
        .careBoxRightBox {
          // width:524px;
          padding-bottom: 6px;
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          img {
            width: 120px;
            height: 120px;
          }
          div {
            margin-left: 16px;
            p:nth-child(1) {
              width: 360px;
              text-align: left;
              height: 25px;
              font-size: 18px;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 25px;
            }
            p:nth-child(2) {
              margin-top: 16px;
              width: 360px;
              height: 36px;
              font-size: 16px;
              font-weight: 400;
              color: #838383;
              line-height: 18px;
              letter-spacing: 1px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            p:nth-child(2):hover {
              color: #299654;
            }
            p:nth-child(3) {
              width: 360px;
              display: flex;
              justify-content: space-between;
              margin-top: 12px;
              span {
                height: 24px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #838383;
                line-height: 24px;
              }
            }
          }
        }
        .careBoxRightBox:nth-child(n + 1) {
          border-bottom: 1px dashed #838383;
        }
      }
    }
  }

  .recommendGoods {
    width: calc(100% + 1px);
    position: relative;
    .click_left,
    .click_right {
      position: absolute;
      width: 60px;
      height: 60px;
      background: #ffffff;
      border-radius: 50%;
      font-size: 28px;
      top: 120px;
      color: #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .click_left {
      left: -80px;
    }
    .click_right {
      right: -80px;
    }
    .recommendGoodsList {
      width: 100%;
      overflow: hidden;
      ul {
        transition: all 1s;
        width: 100%;
        display: flex;
        margin-top: 12px;
        li {
          width: 271px;
          height: 262px;
          background: #ffffff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          flex-shrink: 0;
          margin-right: 13px;
          img {
            width: 271px;
            height: 189px;
            border-radius: 2px 2px 0px 0px;
          }
          .desc {
            padding: 14px 24px;
            div:nth-child(1) {
              width: 178px;
              height: 73px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: black;
              line-height: 33px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
  .gyActive {
    width: 1120px;
    margin-top: 40px;
    display: flex;
    flex-wrap: nowrap;
    .gyActiveLeft {
      width: 540px;
      height: 481px;
      padding-left: 50px;
      display: flex;
      align-items: center;
      div {
        div:nth-child(1) {
          width: 153px;
          height: 50px;
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1a1a1a;
          line-height: 50px;
          letter-spacing: 2px;
        }
        div:nth-child(2) {
          width: 448px;
          height: 150px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #838383;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
      }
    }
    .gyActiveRight {
      width: 560px;
      height: 481px;
      margin-left: 30px;
      .gyActiveRightTop {
        width: 560px;
        height: 285px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.2) 100%
        );
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        position: relative;
        video {
          width: 560px;
          height: 285px;
        }
        img {
          position: absolute;
          width: 80px;
          height: 80px;
          left: 230px;
          top: 100px;
          cursor: pointer;
        }
      }
      .gyActiveRightMain {
        width: 560px;
        height: 285px;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        .gyActiveRightTopMain {
          width: 220px;
          height: 185px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          position: relative;
          video {
            width: 220px;
            height: 185px;
          }
          img {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 70px;
            top: 60px;
            cursor: pointer;
          }
        }
        .gyActiveRightTopMain1 {
          width: 324px;
          height: 185px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          position: relative;
          video {
            width: 324px;
            height: 185px;
          }
          img {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 120px;
            top: 60px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.hotspot {
  width: 1120px;
  // height: 730px;
  background: #ffffff;
  margin: 20px auto 0;
  padding: 24px;
  .goldStoreTitle {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .iconxiangyou1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 33px;
      color: rgba(41, 150, 84, 1);
      margin: 2px 0 0 8px;
    }
  }
  .hotspotTit {
    display: flex;
    flex-wrap: nowrap;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c0c0c0;
    line-height: 17px;
    margin-top: 12px;
    span {
      cursor: pointer;
    }
    span:not(:nth-child(1)) {
      margin-left: 20px;
    }
    span:hover {
      color: rgba(41, 150, 84, 1);
    }
  }
  .hotspotBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    .hotspotBoxSm:hover {
      cursor: pointer;
      transition: all 0.2s;
      transform: scale(1.1);
      border: 1px solid rgba(41, 150, 84, 1);
    }
    .hotspotBoxSm {
      padding: 12px 18px;
      width: 214px;
      height: 299px;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      cursor: pointer;
      img {
        width: 176px;
        height: 175px;
        margin-bottom: 8px;
      }
      .hotspotBoxSmp {
        width: 176px;
        height: 48px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .hotspotBoxPri {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        div:nth-child(1) {
          span:nth-child(1) {
            height: 33px;
            font-size: 24px;
            font-weight: 400;
            color: #e64747;
            line-height: 33px;
          }
          span:nth-child(2) {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #838383;
            line-height: 17px;
            text-decoration: line-through;
          }
        }
        div:nth-child(2) {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #838383;
          line-height: 42px;
        }
      }
    }
  }
}

.videoModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  .videoContent {
    position: absolute;
    width: 1120px;
    height: 800px;
    background: #ffffff;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.2);
      z-index: 9;
      cursor: pointer;
    }
  }
}
</style>
